<template>
  <div class="main">
    <div class="box">
      <div style="height: 30px;line-height: 30px;padding-top: 10px;box-sizing: border-box">
        <!-- <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/seeProject' }">患者中心</el-breadcrumb-item>
          <el-breadcrumb-item>患者画像</el-breadcrumb-item>
        </el-breadcrumb> -->
      </div>
      <div class="content">
        <div class="content-left">
          <div class="patient-info">
            <span>{{ BaseInfo.Name }}</span>
            <span style="margin-left: 20px">{{ BaseInfo.Age }}岁</span>
            <span>{{ BaseInfo.Sex != null ? BaseInfo.Sex == 1 ? "男" : "女" : "" }}</span>
            <img v-if="BaseInfo.Sex == 1" src="../../assets/image/icon/boy-sex.png" alt=""
              style="margin-right: 20px;vertical-align: -1px">
            <img v-else src="../../assets/image/icon/female-sex.png" alt=""
              style="margin-right: 20px;vertical-align: -1px">
            <span>{{ BaseInfo.Tel }}</span>
          </div>
          <div>
            <img v-if="BaseInfo.Sex == 1" src="../../assets/image/icon/boy.png">
            <img v-else src="../../assets/image/icon/girl.png">
          </div>
        </div>
        <el-tabs v-model="activeName">
          <el-tab-pane label="基本信息" name="first">
            <div class="content-background patient-info">
              <el-row>
                <el-col :span="12">
                  <span>患者编号:</span>
                  <span>{{ BaseInfo.No }}</span>
                </el-col>
                <el-col :span="12" class="el-col-tooltip">
                  <span>医保卡号:</span>
                  <el-tooltip class="item" effect="dark" :content="BaseInfo.MiCardNo" placement="top">
                    <span class="tooltipMessage">{{ BaseInfo.MiCardNo }}</span>
                  </el-tooltip>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <span>患者姓名:</span>
                  <span>{{ BaseInfo.Name }}</span>
                </el-col>
                <el-col :span="12">
                  <span>就诊日期:</span>
                  <span>{{ changeTime(BaseInfo.VisitDate) }}</span>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <span>姓名缩写:</span>
                  <span>{{ BaseInfo.NameAbbr }}</span>
                </el-col>
                <el-col :span="12">
                  <span>手术日期:</span>
                  <span>{{ changeTime(BaseInfo.OperationDate) }}</span>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <span>患者电话:</span>
                  <span>{{ BaseInfo.Tel }}</span>
                </el-col>
                <el-col :span="12">
                  <span>风险等级:</span>
                  <span>{{ BaseInfo.RiskRatingName }}</span>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <span>患者微信:</span>
                  <span>{{ BaseInfo.WX }}</span>
                </el-col>
                <el-col :span="12">
                  <span>住院病区:</span>
                  <span>{{ BaseInfo.Ward }}</span>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <span>证件类型:</span>
                  <span>{{ BaseInfo.CertTypeName }}</span>
                </el-col>
                <el-col :span="12">
                  <span>住院天数:</span>
                  <span>{{ BaseInfo.Day }}</span>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <span>证件号码:</span>
                  <span>{{ BaseInfo.CertNo }}</span>
                </el-col>
                <el-col :span="12">
                  <span>出院日期:</span>
                  <span>{{ changeTime(BaseInfo.DischargeDate) }}</span>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <span>出生日期:</span>
                  <span>{{ changeTime(BaseInfo.Birthday) }}</span>
                </el-col>
                <el-col :span="12" class="el-col-tooltip">
                  <span>出院诊断:</span>
                  <el-tooltip class="item" effect="dark" :content="BaseInfo.DischargeDiagnosis" placement="top">
                    <span class="tooltipMessage">{{ BaseInfo.DischargeDiagnosis }}</span>
                  </el-tooltip>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <span>年龄:</span>
                  <span>{{ BaseInfo.Age }}</span>
                </el-col>
                <el-col :span="12" class="el-col-tooltip">
                  <span>次要诊断:</span>
                  <el-tooltip class="item" effect="dark" :content="BaseInfo.SecondaryDiagnosis" placement="top">
                    <span class="tooltipMessage">{{ BaseInfo.SecondaryDiagnosis }}</span>
                  </el-tooltip>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <span>性别:</span>
                  <span>{{ BaseInfo.Sex == 1 ? '男' : "" }}</span>
                  <span>{{ BaseInfo.Sex == 0 ? '女' : "" }}</span>
                </el-col>
                <el-col :span="12">
                  <span>出院方式:</span>
                  <span>{{ BaseInfo.DischargeModeName }}</span>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <span>身高:</span>
                  <span>{{ BaseInfo.Height }}</span>
                </el-col>
                <el-col :span="12">
                  <span>主治医生:</span>
                  <span>{{ BaseInfo.AttendingDoctor }}</span>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <span>体重:</span>
                  <span>{{ BaseInfo.Weight }}</span>
                </el-col>
                <el-col :span="12">
                  <span>随访医生:</span>
                  <span>{{ BaseInfo.FollowUpDoctor }}</span>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <span>患者QQ:</span>
                  <span>{{ BaseInfo.QQ }}</span>
                </el-col>
                <el-col :span="12">
                  <span>随机号:</span>
                  <span>{{ BaseInfo.RandomNumber }}</span>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <span>紧急联系人姓名:</span>
                  <span>{{ BaseInfo.EcName }}</span>
                </el-col>
                <el-col :span="12">
                  <span>组别号:</span>
                  <span>{{ BaseInfo.GroupNumber }}</span>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <span>紧急联系人电话:</span>
                  <span>{{ BaseInfo.EcTel }}</span>
                </el-col>
                <el-col :span="12">
                  <span>教育程度:</span>
                  <span>{{ BaseInfo.Edu }}</span>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <span>患者来源:</span>
                  <span>{{ BaseInfo.SourceName }}</span>
                </el-col>
                <el-col :span="12" class="el-col-tooltip">
                  <span>患者地址:</span>
                  <el-tooltip class="item" effect="dark" :content="BaseInfo.Address" placement="top">

                    <span class="tooltipMessage">{{ BaseInfo.Address }}</span>
                  </el-tooltip>
                </el-col>
              </el-row>
            </div>
          </el-tab-pane>
          <el-tab-pane label="随访信息" name="second">
            <div class="patient-portrait content-background">
              <div v-show="list.length == 0" class="no-followData">
                <img src="../../assets/image/icon/no-content1.png" alt="">
                <p>暂无随访信息。</p>
              </div>
              <div v-show="list.length != 0" class="followData">
                <ul class="infinite-list" style="overflow:auto;height: 580px" @scroll="Scroll">
                  <li v-for="(item, index) in list" :key="index" class="infinite-list-item"
                    @click="EditQuestionnaire(item)">
                    <div style="cursor: pointer">
                      <img src="../../assets/image/icon/follow-info.png" alt="" style="vertical-align: -4px">
                      <span class="title-name"> {{ item.QuestionnaireName }}</span>
                    </div>
                    <div style="width: 100%;height: 80px;padding: 16px 60px 0 19px;box-sizing: border-box"
                      class="content-follow-type">
                      <div style="margin-bottom: 10px;">
                        <span>随访完成时间:</span>
                        <span>{{ item.TriggerTime }}</span>
                        <span>随访状态:</span>
                        <span>{{ item.StatusName }}</span>
                      </div>
                      <div>
                        <span>随访方式:</span>
                        <span>{{ item.ChannelName }}</span>
                      </div>

                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="患者档案" name="three">
            <div class="patient-portrait content-background">
              <el-button @click="AddArchiveDialog">+新建病历</el-button>
              <div v-show="ArchiveInfo.length == 0" class="no-caseData">
                <img src="../../assets/image/icon/img-case.png" alt="">
                <p>暂无患者档案，可以点击新建病历添加。</p>
              </div>
              <div v-show="ArchiveInfo.length != 0" class="caseData-info">
                <div style="display: flex;" v-for="(item, index) in ArchiveInfo" :key="index">
                  <div style="width: 30px;">
                    <img src="../../assets/image/icon/Step-Complete.png" alt="">
                    <div style="width: 1px;height: 140px;background: #E5E5E5;margin-left: 12px">

                    </div>
                  </div>
                  <div>
                    <span class="time">{{ item.ArchiveTime.substr(0, 10) }}</span>
                    <!-- <p>2020年</p> -->
                  </div>
                  <div style="width:300px;">
                    <p style="margin-bottom: 10px;">{{ item.Name }}</p>
                    <p style="margin-bottom: 4px;">
                      <img src="../../assets/image/taskmanagement/projectleader.png" alt=""
                        style="vertical-align: middle">
                      <span style="color: #AAAFB5;font-size: 14px;">上传人:{{ UserName }}</span>
                      <img src="../../assets/image/taskmanagement/time.png" alt="" style="vertical-align:middle">
                      <span
                        style="color: #AAAFB5;font-size: 14px;">上传时间:{{ item.OperTime != null ? item.OperTime.substr(0, 10) : "" }}</span>
                    </p>
                    <p style="color: #AAAFB5;font-size: 14px;margin-bottom: 10px;">{{ item.Remark }}</p>
                    <div style="display: flex;flex-wrap:wrap;justify-content:space-between;">
                      <el-tooltip class="item" effect="dark" :content="row.Name + row.Suffix" placement="top-start"
                        v-for="(row, i) in item.ProjectPatientArchiveAttEntities" :key="i">
                        <div style="width: 64px;height:64px;margin-bottom: 10px;cursor:pointer;" @click="download(row)">
                          <img src="../../assets/image/XLSX.png" style="width:64px;height:64px;"
                            v-show="row.Suffix == '.xlsx'" />
                          <img src="../../assets/image/XLS.png" style="width:64px;height:64px;"
                            v-show="row.Suffix == '.xls'" />
                          <img src="../../assets/image/DOCX.png" style="width:64px;height:64px;"
                            v-show="row.Suffix == '.docx'" />
                          <img src="../../assets/image/DOC.png" style="width:64px;height:64px;"
                            v-show="row.Suffix == '.doc'" />
                          <img src="../../assets/image/PDF.png" style="width:64px;height:64px;"
                            v-show="row.Suffix == '.pdf'" />
                          <img src="../../assets/image/PPT.png" style="width:64px;height:64px;"
                            v-show="row.Suffix == '.ppt'" />
                          <img src="../../assets/image/zip.png" style="width:64px;height:64px;"
                            v-show="row.Suffix == '.zip'" />
                          <img :src="row.Path" style="width:64px;height:64px;"
                            v-show="row.Suffix == '.jpg' || row.Suffix == '.png' || row.Suffix == '.svg'">
                        </div>
                      </el-tooltip>
                    </div>
                  </div>
                  <div style="position: relative;">
                    <div class="el-icon-more" style="width: 160px;text-align: right;cursor:pointer;"
                      @click="ElIconMore(item)">
                    </div>
                    <ul v-show="item.Id == Id"
                      style="position:absolute;top:20px;right:-70px; background:#fff;width: 160px;height: 60px; border-radius: 4px;">
                      <li @click="EditArchiveDialog(item)"
                        style="cursor:pointer;color: #666666;text-align:center;margin-top: 6px;margin-bottom: 4px;"><i
                          class="el-icon-edit-outline"></i>修改</li>
                      <li @click="DeleteArchive(item)" style="cursor:pointer;color: #666666;text-align:center;"><i
                          class="el-icon-delete"></i>删除</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
        <div class="back-patientList">
          <el-button icon="el-icon-back" @click="backOff">返回</el-button>
        </div>
      </div>
    </div>
    <div class="add-patient-archives">
      <el-dialog :visible.sync="dialogVisible" width="500px">
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">{{ type == 1 ? "新增档案" : "修改档案" }}</span>
        </div>
        <el-form label-width="100px" :model="addForm" ref="addForm">
          <el-form-item label="病历标题:" required>
            <el-input v-model="addForm.Name" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="检查时间:" required>
            <el-date-picker v-model="addForm.ArchiveTime" type="date" placeholder="选择日期" value-format="yyyy-MM-dd">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="资料上传:">
            <el-upload class="avatar-uploader" accept=".xlsx,.xls,.jpg,.png,.svg,.pdf,.docx,.ppt,.doc,.zip" multiple
              action="#" :auto-upload="false" :file-list="fileList" :on-change="handleFileChange"
              :on-remove="handleRemove">
              <i class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="备注:">
            <el-input type="textarea" v-model="addForm.Remark"></el-input>
          </el-form-item>
        </el-form>
        <div class="btn">
          <el-button @click="cancel('addForm')">取消</el-button>
          <el-button @click="AddArchive('addForm')">{{ type == 1 ? "保存" : "修改" }}</el-button>
        </div>
      </el-dialog>
    </div>
    <div class="PatientPortraitDialog">
      <el-dialog :visible.sync="PatientPortraitDialog" fullscreen>
        <iframe :src="Url" frameborder="0" width="100%" height="1080px" ref="myFrame"></iframe>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import ProjectManagement from "../../api/ProjectManagement"
import derive from "../../api/derive"
import { local } from "../../utils/storage"
export default {
  name: "SeeProject",
  data() {
    return {
      UserName: local.get('UserName'),
      PatientPortraitDialog: false,
      loading: false,
      addForm: {
        ProjectPatientId: this.$route.query.qId,
        Name: "",
        Remark: "",
        ArchiveTime: "",
        Id: "",
      },
      dialogVisible: false,
      fileList: [],
      activeName: "first",



      BaseInfo: {},
      ArchiveInfo: [],
      Id: "",
      type: 1,
      parameter: [],//储存删除档案Id
      list: [],
      page: 1,
      total: 0,
      flag: false,
      Url: ''
    }
  },
  created() {
    this.PatientProfiling()
    this.FollowUpRecords()
  },
  methods: {
    //时间格式转化
    changeTime: (date) => {
      if (!date) return '';
      return /\d{4}-\d{1,2}-\d{1,2}/g.exec(date)[0];
    },
    cancel() {
      this.dialogVisible = false
    },
    Scroll(e) {
      // console.log(this.$refs.evabox.scrollTop) 
      const ele = e.srcElement ? e.srcElement : e.target
      // 监听滚动到div底部
      if (ele.scrollTop + ele.offsetHeight > ele.scrollHeight - 2) {
        if (this.flag == false) {
          this.flag = true
          this.page++
          if (this.list.length < this.total) {
            this.FollowUpRecords()
          } else {
            this.$message({
              message: '已经没有更多数据了',
              type: 'success'
            });
          }
        }
      }
    },
    //编辑和查看
    EditQuestionnaire(item) {
      if (!item.CollectedQuestionnaireId) return;
      console.log(item, 'resssss11')
      this.PatientPortraitDialog = true;
      this.Url = ''
      if (item.FinishStatus == '02') {
        this.Url = `${window.global_config.BASE_URL1}/#/ViewModification?cqId=${item.CollectedQuestionnaireId}`
      } else {
        derive.EditFollowUpRecordsData().then(res => {
          console.log(res, 'resssss11');
          if (res.data.Status == 1) {
            this.Url = `${res.data.Data.Url}/#/PcQuestionnaire?type=3&qid=${item.CollectedQuestionnaireId}`
          } else {
            alert(res.data.Message)
          }
        })
      }

    },
    //随访记录
    async FollowUpRecords() {
      const parameter = {
        Key: '',
        page: this.page,
        rows: 6,
        ProjectPatientId: this.$route.query.qId
      }
      derive.FollowUpRecordsData(parameter).then(res => {
        console.log(res, "随访记录");
        if (res.data.Status == 1) {
          if (res.data.Data.rows.length > 0) {
            this.total = res.data.Data.total
            this.list = this.list.concat(res.data.Data.rows)
            this.flag = false
          }
        } else {
          alert(res.data.Message)
        }
      })
    },


    //下载
    download(row) {
      window.open(row.Path, "_blank");
      console.log(row);
    },
    //删除档案回调
    handleRemove(file) {
      this.parameter.push(file.Id)
    },
    //打开修改患者档案弹框
    EditArchiveDialog(item) {
      this.parameter = []
      this.type = 2
      this.addForm.Name = item.Name
      this.addForm.Remark = item.Remark
      this.addForm.ArchiveTime = item.ArchiveTime
      this.addForm.Id = item.Id
      this.dialogVisible = true
      this.fileList = item.ProjectPatientArchiveAttEntities.map(row => {
        row["name"] = row.Name + row.Suffix
        return row
      })
      console.log(item);
    },
    ElIconMore(item) {
      if (item.Id == this.Id) {
        this.Id = ""
      } else {
        this.Id = item.Id
      }
    },
    //患者档案
    DeleteArchive(item) {
      const parameter = []
      parameter.push(item.Id)
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        ProjectManagement.DeleteArchiveData(parameter).then(res => {
          if (res.data.Status == 1) {
            this.ArchiveInfo = this.ArchiveInfo.filter(row => {
              return item.Id != row.Id
            })
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
          } else {
            alert(res.data.Message)
          }
        })
      })
    },
    //返回
    backOff() {
      this.$store.commit("ElTabsFn", "three");
      // this.$router.push({ path: "/seeProject", query: { Id: this.$route.query.Id} }); //患者画像
      this.$router.go(-1);
    },
    //患者列表
    async PatientProfiling() {
      const parameter = {
        id: this.$route.query.qId
      }
      ProjectManagement.PatientProfilingData(parameter).then(res => {
        if (res.data.Status == 1) {
          this.ArchiveInfo = res.data.Data.ArchiveInfo
          this.BaseInfo = res.data.Data.BaseInfo
        } else {
          alert(res.data.Message)
        }
        console.log(res, "患者列表");
      })
    },
    AddArchiveDialog() {
      this.fileList = []
      this.addForm = {
        ProjectPatientId: this.$route.query.qId,
        Name: "",
        Remark: "",
        ArchiveTime: "",
        Id: "",
      },
        this.type == 1
      this.dialogVisible = true
    },
    //新增患者档案
    AddArchive(formName) {
      if (this.type == 2) {
        if (this.parameter.length != 0) {
          ProjectManagement.DeleteAttArchiveData(this.parameter).then(res => {
            if (res.data.Status == 1) {
              console.log(res);
            } else {
              alert(res.data.Message)
            }
          })
        }
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let fd = new FormData();
            this.fileList.forEach(file => {
              console.log(file, "%%%%%%%%%");
              if (file.raw) {
                fd.append(`file${i + 1}`, file.raw)
              }
            })
            fd.append("Id", this.addForm.Id);
            fd.append("ProjectPatientId", this.addForm.ProjectPatientId);
            fd.append("Name", this.addForm.Name);
            fd.append("Remark", this.addForm.Remark);
            fd.append("ArchiveTime", this.addForm.ArchiveTime);
            ProjectManagement.EditArchiveData(fd).then(res => {
              console.log(res, "修改");
              if (res.data.Status == 1) {
                this.ArchiveInfo = this.ArchiveInfo.map(item => {
                  if (this.addForm.Id == item.Id) {
                    item = res.data.Data
                  }
                  return item
                })
                this.$message({
                  type: 'success',
                  message: '修改成功!'
                });
                this.dialogVisible = false
              } else {
                alert(res.data.Message)
              }
            })
          }
        });
        return
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let fd = new FormData();
          this.fileList.forEach((file, i) => {
            console.log(file, "%%%%%%%%%");
            fd.append(`file${i + 1}`, file.raw)
          })
          fd.append("ProjectPatientId", this.addForm.ProjectPatientId);
          fd.append("Name", this.addForm.Name);
          fd.append("Remark", this.addForm.Remark);
          fd.append("ArchiveTime", this.addForm.ArchiveTime);
          ProjectManagement.AddArchiveData(fd).then(res => {
            if (res.data.Status == 1) {
              this.$message({
                type: 'success',
                message: '保存成功!'
              });
              this.ArchiveInfo.unshift(res.data.Data)
              this.dialogVisible = false
            } else {
              alert(res.data.Message)
            }
          })
        }
      });
    },
    // beforeAvatarUpload(file) {
    //   const isLt2M = file.size / 1024 / 1024 < 2;
    //   if (!isLt2M) {
    //     this.$message.error('上传头像图片大小不能超过 2MB!');
    //   }
    //   console.log(this.fileList)
    //   return isLt2M;
    // },
    handleFileChange(file, fileList) {
      console.log(fileList, "222");
      this.fileList = fileList;
    },
  }
}
</script>

<style scoped lang="scss">
@import "../../styles/mixin.scss";

.PatientPortraitDialog {
  ::v-deep .el-dialog__headerbtn {
    font-size: 22px;
    top: 4px;
  }
}

.main {
  width: 100%;
  min-width: 1440px;
  height: 100%;
  overflow: hidden;

  .box {
    width: calc(100% - 40px);
    height: calc(100% - 50px);
    margin: 0 auto;
    border-radius: 10px;

    .patient-info {
      padding-top: 10px;
      box-sizing: border-box;

      ::v-deep .el-row {
        margin-bottom: 18px;

        .el-col:nth-child(1) {
          span {
            @include common_span;
          }

          span:nth-child(1) {
            width: 128px;
            display: inline-block;
            text-align: right;
          }
        }

        .el-col:nth-child(2) {
          span {
            @include common_span;
          }

          span:nth-child(1) {
            width: 100px;
            display: inline-block;
            text-align: right;
          }
        }
      }
    }

    .content {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      padding: 20px 20px 0 20px;
      box-sizing: border-box;
      background-image: url("../../assets/image/icon/patient-huaxiang.png");
      border-radius: 10px;

      .content-left {
        width: 300px;
        margin-right: 30px;

        .patient-info {
          span {
            @include common_span
          }

          span:nth-child(1) {
            font-size: 24px;
            font-weight: 500;
            line-height: 41px;
            color: #585858;
          }
        }
      }

      ::v-deep .el-button {
        @include common_button_plain;
        margin: 16px 0 0 34px;
      }

      .back-patientList {
        position: fixed;
        top: 126px;
        right: 60px;

        ::v-deep .el-button {
          @include common_button_primary;
          margin: 16px 0 0 34px;
        }
      }
    }

    ::v-deep .el-tabs__header {
      width: 690px;
      margin-top: 10px;
      background: #ECF2F8;
      border-radius: 30px;
    }

    ::v-deep .el-tabs__item {
      width: 230px;
      height: 40px;
      text-align: center;
      font-size: 16px;
      font-weight: 400;
      color: #585858;
    }

    ::v-deep .el-tabs__item.is-active {
      color: #FFFFFF;
      background: linear-gradient(90deg, #2F80ED 0%, #56CCF2 100%);
      border-radius: 30px;
    }

    ::v-deep .el-tabs__nav-wrap::after {
      height: 0;
    }

    ::v-deep .el-tabs__active-bar {
      background: transparent;
    }

    .content-background {
      width: 690px;
      height: 660px;
      background: rgba(255, 255, 255, 0.39);
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.07);
      border-radius: 10px;
    }

    .patient-portrait {
      width: 690px;

      .no-followData {
        width: 288px;
        text-align: center;
        margin: 0 auto;
        padding-top: 176px;
        box-sizing: border-box;

        p {
          width: 288px;
          font-size: 16px;
          font-weight: 400;
          line-height: 27px;
          color: #AAAFB5;
        }
      }

      .followData {
        padding: 37px 59px 0 61px;

        ul {
          li {
            .title-name {
              width: 494px;
              height: 18px;
              font-size: 18px;
              font-weight: 500;
              line-height: 31px;
              color: #333333;
            }

            .content-follow-type {
              span {
                @include common_span
              }
            }
          }
        }
      }

      .no-caseData {
        width: 288px;
        text-align: center;
        margin: 121px auto 0 auto;

        p {
          width: 288px;
          font-size: 16px;
          font-weight: 400;
          line-height: 27px;
          color: #AAAFB5;
        }
      }

      .caseData-info {
        width: 690px;
        margin: 0 auto;
        padding: 24px 0 0 34px;
        box-sizing: border-box;
        overflow: auto;
        height: 570px;

        p {
          @include common_span
        }

        span {
          @include common_span;
          @include add-size5($font_size_14)
        }

        .time {
          @include add-size($font_size_16);
          color: #3388FF;
        }

        .bei-p {
          @include add-size5($font_size_14)
        }
      }
    }
  }
}

::v-deep .avatar-uploader .el-upload {
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

::v-deep .avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

::v-deep .avatar-uploader-icon {
  font-size: 20px;
  color: #8c939d;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
}

::v-deep .avatar {
  width: 50px;
  height: 50px;
  display: block;
}

.tooltipMessage {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 60%;
  display: inline-block;
}

.el-col-tooltip {
  display: flex;
  align-items: center;
}

@media screen and (-webkit-min-device-pixel-ratio: 1.5) {

  .main {
    min-width: 0 !important;

    .content-left {
      width: 200px !important;
      margin-right: 0 !important;
    }

    .el-tabs {
      flex: 1;

      ::v-deep .el-tabs__header {
        width: 360px !important;

        .el-tabs__item {
          width: 120px !important;
        }
      }

      .content-background {
        width: 100% !important;
        text-align: left !important;
      }
    }
  }


}</style>

